import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Shop, ShopsService} from '../../services/shops.service';

@Component({
  selector: 'app-first-page-registration',
  templateUrl: './first-page-registration.component.html',
  styleUrls: ['./first-page-registration.component.css']
})
export class FirstPageRegistrationComponent implements OnInit {

  registrationForm: FormGroup;
  messages = [];
  shops: Shop[] = [];
  isLoading$ = new BehaviorSubject(true);
  registrationFormVisible$ = new BehaviorSubject(false);
  registrationSuccessfulMessageVisible$ = new BehaviorSubject(false);

  emailFieldValidators = [Validators.maxLength(200), Validators.email];
  phoneNumberValidators = [Validators.maxLength(20)];

  constructor(private fb: FormBuilder,
              private shopsService: ShopsService) {

    this.registrationForm = this.fb.group({
      firstName: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(2)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(2)
      ]],
      email: ['', this.emailFieldValidators],
      phoneNumber: ['', this.phoneNumberValidators],
      shop: [null, Validators.required],
      description: ['', Validators.maxLength(500)]
    });
  }

  ngOnInit(): void {
    this.registrationFormVisible$.next(true);
    this.shopsService.findAllShops().subscribe(shops => {
      this.shops = shops.sort((s1, s2) => {
        if (s1.name > s2.name) {
          return 1;
        }

        if (s1.name < s2.name) {
          return -1;
        }

        return 0;
      });
    }, () => {
      this.messages.push({type: 'error', text: $localize `Poodide laadimine ebaõnnestus`});
    }, () => {
      this.isLoading$.next(false);
    });
  }

  sendRegistration(): void {
    this.messages = [];
    this.validateRegistration();

    if (this.registrationForm.valid && this.hasNoMessages()) {
      console.log(this.registrationForm.value);
    }
  }

  validateRegistration(): void {
    if (this.registrationForm.get('firstName').invalid) {
      this.messages.push({type: 'error', text: $localize `Eesnimi on kohustuslik`});
    }
    if (this.registrationForm.get('lastName').invalid) {
      this.messages.push({type: 'error', text: $localize `Perenimi on kohustuslik`});
    }
    if (this.registrationForm.get('shop').invalid) {
      this.messages.push({type: 'error', text: $localize `Palun vali pood`});
    }
    if (this.registrationForm.get('email').value.trim() === '' && this.registrationForm.get('phoneNumber').value.trim() === '') {
      this.messages.push({type: 'error', text: $localize `Palun lisa kas e-posti aadress või telefoni number`});
    }
  }

  hasNoMessages(): boolean {
    return this.messages.length === 0;
  }
}
