<div id="page" class="container">
  <div class="row">
    <div id="page-content" class="col-lg-12">
      <div *ngIf="isItemSelected()">
        <app-news-item [item]="selectedItem" (showList)="showAllItems()"></app-news-item>
      </div>
      <div *ngIf="!isItemSelected()">
        <app-news-list [page]="page" [items]="newsItems" (itemSelected)="selectItem($event)"></app-news-list>
      </div>
    </div>
  </div>
</div>
