<!-- Add your site or application content here -->
<div>
  <app-header></app-header>

  <router-outlet></router-outlet>

  <div id="frames" class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="heading-box">
          <div class="heading-line">
            <hr>
          </div>
          <h2 class="heading" i18n>Frames</h2>
        </div>
        <img alt="Lens Optika" src="assets/images/lens_optika_prilliraamid.png" class="img-responsive center-block">
      </div>
    </div>
  </div>

  <div id="footer-links" class="container">
    <div class="row">
      <div class="col-lg-12"></div>
    </div>
    <hr>
  </div>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <h4 class="text-primary">Administratsioon</h4>

          <p>Solomonia OÜ<br>Telefon: +372 6 311 465<br>E-post: office(ät)lensoptika.ee<br>Aadress: Ravi 14,
            Tallinn 10138<br>Reg. nr 10477111<br>KMKR EE100379547</p>
        </div>
        <div class="col-lg-5 col-lg-next">
          <h4 class="text-primary">Lens Optika on Eesti Patendiametis kaitstud kaubamärk</h4>

          <p>Lens Optika kaubamärgi all tegutseb osaühing Solomonia. Lens Optika ja OÜ Solomonia
            administratsioon asuvad
            samal aadressil.</p>
        </div>
        <div class="col-lg-4 col-lg-next">
          <h4 class="text-primary">Liitu meiega Facebookis</h4>

          <p>...ja oled alati kursis viimaste uudiste ja parimate pakkumistega!</p>

          <div class="fb-like-box" data-href="https://www.facebook.com/pages/Lens-Optika/112533148783093"
               data-width="200" data-show-border="false" data-colorscheme="dark" data-show-faces="false"
               data-header="false" data-stream="false"></div>
        </div>
      </div>
    </div>
  </footer>

</div>
