<div id="page" class="container">
  <div class="row">
    <div id="page-content" class="col-lg-12" *ngIf="page">
      <div class="heading-box">
        <div class="heading-line">
          <hr>
        </div>
        <h1 class="heading" i18n>{{page.name}}</h1>
      </div>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <div class="col-lg-8 col-lg-push-2">
              <fieldset [disabled]="isLoading$ | async">
                <div class="row">
                  <div messages></div>
                </div>
                <form [formGroup]="feedbackForm" (ngSubmit)="sendFeedback()" class="lo-form">
                  <div class="form-group">
                    <label for="feedback_email" i18n>E-mail</label>
                    <input type="email" class="form-control" id="feedback_email" formControlName="email">
                  </div>
                  <div class="form-group">
                    <label for="feedback_phone" i18n>Phone number</label>
                    <input type="tel" class="form-control" id="feedback_phone" formControlName="phone">
                  </div>
                  <div class="form-group">
                    <label for="feedback_content" i18n>Content</label>
                    <textarea class="form-control" id="feedback_content" formControlName="content" rows="5"></textarea>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary" [disabled]="feedbackForm.invalid" i18n>Send</button>
                  </div>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
