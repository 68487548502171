import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsRoutingModule} from './news-routing.module';
import {NewsComponent} from './news/news.component';
import {NewsListComponent} from './news-list/news-list.component';
import {NewsItemComponent} from './news-item/news-item.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    NewsComponent,
    NewsListComponent,
    NewsItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NewsRoutingModule,
  ]
})
export class NewsModule {
}
