import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedbackRoutingModule} from './feedback-routing.module';
import {FeedbackComponent} from './feedback/feedback.component';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FeedbackRoutingModule
  ]
})
export class FeedbackModule {
}
