import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface Shop {
  publicId: string;
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  constructor(private http: HttpClient) {
  }

  findAllShops(): Observable<Shop[]> {
    return this.http.get('/api/shops') as Observable<Shop[]>;
  }
}
