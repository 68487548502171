<div class="container">
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2" i18n>Page not found</h1>
  </div>
  <div>
    <p i18n>Oops, you tried to go somewhere that doesn't exist.</p>
  </div>
  <div>
    <button class="btn btn-sm btn-link" (click)="back()" i18n>Go back</button>
  </div>
</div>
