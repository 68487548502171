<div id="navbar-top">
  <div class="container">
    <div class="row">
      <div id="web-navbar" class="col-lg-12 text-right yamm">
        <a href="https://www.silmakabinet.eu" class="btn btn-sm btn-default navbar-btn sr-only" role="button">Jaan
          Reimandi Silmakabinet <i class="fa fa-eye"></i></a>
        <a href="https://shop.lensoptika.ee" class="btn btn-sm btn-default navbar-btn" role="button"
           target="_blank">Lens Optika e-pood <i class="fa fa-shopping-cart"></i></a>

        <app-change-language-button></app-change-language-button>

        <a href="https://www.facebook.com/pages/Lens-Optika/112533148783093"
           class="btn btn-sm btn-default navbar-btn"
           role="button" target="_blank"><i class="fa fa-facebook"></i></a>
      </div>
    </div>
  </div>

  <nav role="navigation" class="navbar navbar-default navbar-inverse navbar-static-top">
    <div class="container">
      <div class="navbar-header">
        <button aria-controls="navbar" aria-expanded="false" data-target="#navbar" data-toggle="collapse"
                class="navbar-toggle collapsed" type="button">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a href="" [routerLink]="['/']" class="navbar-brand">
          <img src="assets/images/lens_optika_logo.png" alt="Lens Optika" style="height: 23px;">Sinu optiline isikupära
        </a>
      </div>
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="nav navbar-nav navbar-right yamm">
          <li *ngFor="let page of mainNavigationItems" [ngClass]="{'dropdown yamm-fw': hasSubItems(page)}">
            <a href="" [routerLink]="[page.location, page.id]" role="button" *ngIf="!hasSubItems(page)">{{page.name}}</a>
            <a href="" [routerLink]="[page.location, page.id]" role="button" [ngClass]="{'dropdown-toggle menu-down': hasSubItems(page)}" *ngIf="hasSubItems(page)" data-toggle="dropdown" aria-expanded="false">{{page.name}} <i *ngIf="hasSubItems(page)" class="fa fa-angle-down"></i></a>
            <ul *ngIf="hasSubItems(page)" class="dropdown-menu" role="menu">
              <li class="container">
                <ul class="col-lg-12 list-unstyled">
                  <li *ngFor="let item of page.items" class="link">
                    <a title="{{item.name}}" href="" [routerLink]="[item.location, item.id]">{{item.name}}</a>
                    <ul *ngIf="hasSubItems(item)">
                      <li *ngFor="let iii of item.items" class="sublink">
                        <a href="" [routerLink]="[iii.location, iii.id]">{{iii.name}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <app-carousel></app-carousel>
</div>
