<div class="heading-box">
  <div class="heading-line">
    <hr>
  </div>
  <h2 class="heading">{{page.name}}</h2>
</div>
<div class="panel panel-default">
  <div class="panel-body">
    <hr class="first">
    <div class="row col-lg-next" *ngFor="let item of items">
      <div class="news-date col-lg-2">
        <span>{{item.insertedAt.date | date: 'dd.MM.yyyy'}}</span>
      </div>
      <div class="news-heading col-lg-10">
        <a href="" (click)="selectItem(item, $event)">{{item.title}}</a>
      </div>
    </div>

    <div class="custom-divider margin-top"></div>

    <div class="text-center sr-only">
      <ul class="pagination">
        <li>
          <a href="#" aria-label="Eelmine">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="active"><a href="#">1 <span class="sr-only">(current)</span></a></li>
        <li><a href="#">2</a></li>
        <li>
          <a href="#" aria-label="Järgmine">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
