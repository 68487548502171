import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NewsItem} from '../../../services/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {

  @Input() items: NewsItem[];
  @Input() page;
  @Output() itemSelected: EventEmitter<NewsItem> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectItem(item: NewsItem, event): void {
    event.preventDefault();
    this.itemSelected.emit(item);
  }
}
