import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Banner, BannersService} from '../../../services/banners.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  isVisible = true;
  banners: Banner[] = [];

  constructor(private router: Router,
              private bannersService: BannersService) {
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.isVisible = e.url === '/';
      });

    this.bannersService.findBanners().subscribe(banners => {
      this.banners.push(banners[1]);
    }, (e: HttpErrorResponse) => {
      console.error(`loading of banner items failed - ${e.message}`);
    });
  }
}
