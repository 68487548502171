import {Component, OnInit} from '@angular/core';
import {PagesService} from '../../../services/pages.service';
import {LanguagesService} from '../../../services/languages.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  mainNavigationItems = [];

  constructor(private pagesService: PagesService,
              private languagesService: LanguagesService) {
  }

  ngOnInit(): void {
    this.pagesService.loadStructure(this.languagesService.getSelectedLanguageCode()).subscribe(pages => {
      this.mainNavigationItems = pages;
    });
  }

  hasSubItems(item): boolean {
    return item.items && item.items.length > 0;
  }
}
