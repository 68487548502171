import {Component, OnInit} from '@angular/core';
import {NewsItem, NewsService} from '../../../services/news.service';
import {ActivatedRoute} from '@angular/router';
import {PagesService} from '../../../services/pages.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsItems: NewsItem[] = [];
  selectedItem: NewsItem = null;
  page = {};

  constructor(private newsService: NewsService,
              private pagesService: PagesService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pagesService.findById(params.pageId).subscribe(page => {
        this.page = page;

        this.newsService.findNewsForPage(params.pageId).subscribe(items => {
          this.newsItems = items;
        }, (e: HttpErrorResponse) => {
          console.error(`loading of news items failed - ${e.message}`);
        });
      }, (e: HttpErrorResponse) => {
        console.error(`loading of page failed - ${e.message}`);
      });
    });
  }

  isItemSelected(): boolean {
    return this.selectedItem != null;
  }

  selectItem(item): void {
    this.selectedItem = item;
  }

  showAllItems(): void {
    this.selectedItem = null;
  }
}
