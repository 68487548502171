import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FeedbackService} from '../../../services/feedback.service';
import {BehaviorSubject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Page, PagesService} from '../../../services/pages.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  page: Page = null;
  isLoading$ = new BehaviorSubject(false);
  feedbackForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    phone: ['', [Validators.minLength(7), Validators.required]],
    content: ['', Validators.required],
  });

  constructor(private feedbackService: FeedbackService,
              private pagesService: PagesService,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pagesService.findById(params.pageId).subscribe(page => {
        this.page = page;
      });
    });

    this.addEitherPhoneOrEmailRequiredValidator();
  }

  addEitherPhoneOrEmailRequiredValidator(): void {
    const emailControl = this.feedbackForm.get('email');
    const phoneControl = this.feedbackForm.get('phone');

    this.feedbackForm.get('email').valueChanges.subscribe(value => {
      if (value.trim() === '') {
        phoneControl.markAsPristine();
        phoneControl.setValidators([Validators.required, Validators.minLength(7)]);
      } else {
        console.log('erase validators for phone');
        phoneControl.setValidators(null);
        // emailControl.setValidators([Validators.required, Validators.email]);
      }
      // phoneControl.updateValueAndValidity();
      // emailControl.updateValueAndValidity();
    });

    this.feedbackForm.get('phone').valueChanges.subscribe(value => {
      if (value.trim() === '') {
        emailControl.markAsPristine();
        emailControl.setValidators([Validators.required, Validators.email]);
      } else {
        console.log('erase validators for email');
        emailControl.setValidators(null);
      }
      // emailControl.updateValueAndValidity();
      // phoneControl.updateValueAndValidity();
    });
  }

  sendFeedback(): void {
    this.isLoading$.next(true);
    this.feedbackService.send(this.feedbackForm.value).subscribe(() => {

    }, (e: HttpErrorResponse) => {
      // TODO: sending failed
    });
  }

  // requiredIf(condition: (control: AbstractControl) => boolean): (control: FormControl) => ValidationResult {
  //   return (control: FormControl): ValidationResult => {
  //     // Important: control.parent might not be defined, depending on your form structure
  //     return !condition(control.parent) || !!control.value ? null : { [`required_if`]: true };
  //   };
  // }

  // emailConditionallyRequiredValidator(formControl: AbstractControl): void {
  //   if (formControl.parent) {
  //     formControl.parent.get('phone').valueChanges.subscribe(value => {
  //       if (value.trim() === '') {
  //         Validators.required(formControl);
  //       }
  //     });
  //   }
  // }
  //
  // phoneConditionallyRequiredValidator(formControl: AbstractControl): void {
  //   if (formControl.parent) {
  //     formControl.parent.get('email').valueChanges.subscribe(value => {
  //       if (value.trim() === '') {
  //         Validators.required(formControl);
  //       }
  //     });
  //   }
  // }
}
