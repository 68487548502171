import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {ChangeLanguageButtonComponent} from './change-language-button/change-language-button.component';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {CarouselComponent} from './carousel/carousel.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ChangeLanguageButtonComponent,
    CarouselComponent,
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
  ]
})
export class LayoutModule {
}
