<div class="btn-group dropdown navbar-btn">
  <button id="dropdownLanguage" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="true">{{selectedLanguage.label}} <i class="fa fa fa-globe"> </i> <i
    class="fa fa-angle-down"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-right col-lg-3 col-md-4 col-sm-6 col-xs-12" role="menu">
    <ul class="list-unstyled">
      <li class="list-group-item" *ngFor="let l of supportedLanguages">
        <a href="" (click)="chooseLanguage(l, $event)">
          <span>{{l.label}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
