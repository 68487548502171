import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NewsItem} from '../../../services/news.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css']
})
export class NewsItemComponent implements OnInit {

  @Input() item: NewsItem;
  @Output() showList: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  showAllItems(event): void {
    event.preventDefault();
    this.showList.emit();
  }
}
