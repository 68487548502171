import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NewsModule} from './modules/news/news.module';
import {ContentModule} from './modules/content/content.module';
import {FeedbackModule} from './modules/feedback/feedback.module';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {WelcomePageComponent} from './components/welcome-page/welcome-page.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomePageComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [
    NewsModule,
    ContentModule,
    FeedbackModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
