import {Component, OnInit} from '@angular/core';
import {Content, Page, PagesService} from '../../../services/pages.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  page: Page = null;
  content: Content = null;

  constructor(private route: ActivatedRoute,
              private pagesService: PagesService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pagesService.findById(params.pageId).subscribe(page => {
        this.page = page;

        this.pagesService.findContentForPage(page).subscribe(content => {
          this.content = content;
        });
      });
    });
  }
}
