<div class="container" *ngIf="isVisible">
  <div class="row">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators col-lg-4 col-sm-6 col-xs-12 text-center col-lg-offset-8 col-sm-offset-6 hidden-sm">
        <li *ngFor="let banner of banners; let index = index; let isFirst = first;" data-target="#myCarousel" [ngClass]="{active: isFirst}" [attr.data-slide-to]="index"></li>
      </ol>
      <div class="carousel-inner">
        <div class="item" *ngFor="let banner of banners; let isFirst = first;" [ngClass]="{active: isFirst}">
          <a href="{{banner.url}}" *ngIf="banner.url"><img class="col-lg-8 col-sm-6" [src]="banner.image.url" title="{{banner.image.title}}" alt="{{banner.image.title}}"></a>
          <img class="col-lg-8 col-sm-6" title="{{banner.image.title}}" alt="{{banner.image.title}}" [src]="banner.image.url" *ngIf="!banner.url">
          <div class="col-lg-4 col-sm-6">
            <div class="carousel-caption">
              <h3>{{banner.title}}</h3>
              <div [innerHTML]="banner.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
