import {Injectable} from '@angular/core';

export type Language = {
  code: string;
  label: string;
};

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  private supportedLanguages = [
    {code: 'et', label: 'Eesti keel'},
    {code: 'en', label: 'in english'},
    {code: 'ru', label: 'Пo-pyccku'},
    {code: 'fi', label: 'Suomeksi'},
  ];
  private selectedLanguage = null;

  constructor() {
    const locale = window.location.pathname.split('/')[1];
    this.selectedLanguage = this.supportedLanguages.find(f => f.code === locale);

    if (!this.selectedLanguage) {
      this.selectedLanguage = this.supportedLanguages[0];
    }
  }

  getSupportedLanguages(): Language[] {
    return this.supportedLanguages;
  }

  getSelectedLanguage(): Language {
    return this.selectedLanguage;
  }

  getSelectedLanguageCode(): Language {
    return this.selectedLanguage.code;
  }

  changeLanguage(language: Language): void {
    this.selectedLanguage = language;
    window.location.href = `/${language.code}`;
  }
}
