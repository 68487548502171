import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface Page {
  id: number;
  name: string;
  language: string;
}

export interface Content {
  id: number;
  pageId: number;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpClient) {
  }

  loadStructure(language): Observable<Page[]> {
    return this.http.get(`/api/structure/${language}`) as Observable<Page[]>;
  }

  findById(id): Observable<Page> {
    return this.http.get(`/api/pages/${id}`) as Observable<Page>;
  }

  findContentForPage(page: Page): Observable<Content> {
    return this.http.get(`/api/pages/${page.id}/content`) as Observable<Content>;
  }
}
