import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DateTimeWithTimezone} from './DateTimeWithTimezone';

export interface NewsItem {
  id: number;
  title: string;
  content: string;
  pageId: number;
  visibleOnFirstPage: boolean;
  language: string;
  public: boolean;
  validUntil: DateTimeWithTimezone;
  insertedAt: DateTimeWithTimezone;
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) {
  }

  findNewsForPage(pageId): Observable<NewsItem[]> {
    return this.http.get(`/api/pages/${pageId}/news`) as Observable<NewsItem[]>;
  }
}
