<div id="page-content-right-frontpage" class="col-lg-6 col-md-6 col-lg-push-6 col-md-push-6">
  <div id="registration_messages">
    <div *ngFor="let m of messages" class="alert" [ngClass]="{'alert-danger': m.type == 'error', 'alert-success': m.type == 'success'}">
      <span>{{m.text}}</span>
    </div>
  </div>
  <div id="registrationFormContainer" *ngIf="(registrationFormVisible$ | async)">
    <fieldset [disabled]="(isLoading$ | async)">
      <form [formGroup]="registrationForm" (ngSubmit)="sendRegistration()" class="lo-form">
        <div class="form-group">
          <label for="registration_first_name" i18n>Eesnimi</label>
          <input type="text" class="form-control" id="registration_first_name" formControlName="firstName">
        </div>
        <div class="form-group">
          <label for="registration_last_name" i18n>Perenimi</label>
          <input type="text" class="form-control" id="registration_last_name" formControlName="lastName">
        </div>
        <div class="form-group">
          <label for="registration_email" i18n>E-posti aadress</label>
          <input type="email" class="form-control" id="registration_email" formControlName="email">
        </div>
        <div class="form-group">
          <label for="registration_phone" i18n>Kontakttelefon</label>
          <input type="tel" class="form-control" id="registration_phone" formControlName="phoneNumber">
        </div>
        <div class="form-group">
          <label for="registration_shop_id" i18n>Millises kaupluses soovid nägemist kontrollida</label>
          <select id="registration_shop_id" class="form-control" formControlName="shop">
            <option></option>
            <option *ngFor="let shop of shops">{{shop.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="registration_short_description" i18n>Lühikirjeldus</label>
          <textarea class="form-control" id="registration_short_description" rows="3" formControlName="description"></textarea>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary" i18n>Registreeru</button>
        </div>
      </form>
    </fieldset>
  </div>
  <div id="registrationSuccessfulMessage" *ngIf="(registrationSuccessfulMessageVisible$ | async)">
    <p>Võtame Teiega 24h jooksul ühendust.</p>
  </div>
</div>
