<div id="page" class="container">
  <div class="row">
    <ol class="breadcrumb sr-only">
      <li><a href="#">Avaleht</a></li>
      <li>Pakkumised</li>
      <li class="active">Kampaania</li>
    </ol>

    <div *ngIf="page">
      <div id="page-content" class="col-lg-12">
        <div class="heading-box">
          <div class="heading-line">
            <hr>
          </div>
          <h1 class="heading">{{page.name}}</h1>
        </div>
        <div class="panel panel-default">
          <div class="panel-body">

            <div class="row">
              <div class="col-lg-8 col-lg-push-2" *ngIf="content">
                <div [innerHTML]="content.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
