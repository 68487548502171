<div class="heading-box">
  <div class="heading-line">
    <hr>
  </div>
  <h2 class="heading">{{item.title}}</h2>
</div>
<div class="panel panel-default">
  <div class="panel-body">
    <hr class="first">

    <h3>{{item.insertedAt.date | date: 'dd.MM.yyyy'}}</h3>
    <div class="custom-divider"></div>
    <div [innerHTML]="item.content"></div>

    <div style="margin-top: 1em;">
      <a href="" (click)="showAllItems($event)">&laquo; Tagasi</a>
    </div>
  </div>
</div>
