<div id="products-register" class="container">
  <div class="row">
    <div class="col-md-4">
      <div class="heading-box">
        <div class="heading-line">
          <hr>
        </div>
        <h2 class="heading" i18n>Kauplused</h2>
      </div>
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="assets/images/lens_optika_kauplus_sikupilli_md.jpg" class="img-responsive img-panel" alt="Lens Optika">
          <p i18n>Lens Optika kauplused asuvad Tallinnas, Haapsalus, Jõhvis ja Narvas.</p>
          <p><a class="btn btn-default" href="" [routerLink]="['content', 587]" role="button" i18n>Kontaktid</a></p>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="heading-box">
        <div class="heading-line">
          <hr>
        </div>
        <h2 class="heading" i18n>Registreeru nägemiskontrolli</h2>
      </div>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <app-first-page-registration></app-first-page-registration>
            <div id="page-content-left-frontpage" class="col-lg-6 col-md-6 col-sm-next col-lg-pull-6 col-md-pull-6">
              <h3>Kõikides meie kauplustes saab teostada nägemiskontrolli</h3>
              <p>Kaasajal teostavad nägemise kontrolli peamiselt optometristid. Optometrist on rakenduskõrgharidusega spetsialist, kes on läbinud 3,5 aastat kestva õppetöö Tallinna Tervishoiu Kõrgkoolis.</p>
              <p>Täpsemalt saad optometristi elukutse kohta lugeda <a target="_blank" title="Optometristi elukutse" href="https://www.ttk.ee/?id=26854">siit</a>. Üksikasjalikult kirjeldab optometristile esitatavaid nõudeid <a target="_blank" href="https://www.kutsekoda.ee/download.aspx/download/259/Optometrist%20III,%20IV%20'03.rtf">optometristi kutsestandard</a>.</p>
              <p>Silmaarsti ehk oftalmoloogi peamiseks tööks on silmahaiguste ravimine.</p>
              <p>Kohtades kus optometriste veel ei jätku, tegelevad silmaarstid ka nägemiskontrolliga. Silmaarst tegeleb ka alla 12 aastaste laste nägemiskontrolliga, samuti nägemise kontrolliga eriti keerulistel juhtudel.</p>
              <p>Silmaarsti vastuvõtule on vajalik pöörduda, kui teil on kahtlusi silmahaiguse olemasolule või kui soovite saada hinnangut silmade tervisliku seisundi kohta.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
