import {Component} from '@angular/core';
import {Language, LanguagesService} from '../../../services/languages.service';

@Component({
  selector: 'app-change-language-button',
  templateUrl: './change-language-button.component.html',
  styleUrls: ['./change-language-button.component.css']
})
export class ChangeLanguageButtonComponent {
  supportedLanguages: Language[] = [];
  selectedLanguage = this.languagesService.getSelectedLanguage();

  constructor(private languagesService: LanguagesService) {
    this.supportedLanguages = languagesService.getSupportedLanguages();
  }

  chooseLanguage(language: Language, event): void {
    event.preventDefault();
    this.languagesService.changeLanguage(language);
  }
}
